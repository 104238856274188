import React, { useEffect, useState } from "react";
import { Container, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChartAnalysis from "./ChartAnalysis";
import GraphTable from "../GraphTable";
import TaskPopUp from "./TaskPopUp";
import useCountryList from "../../actions/TaskUpload/useCountryList";
import { useSelector, useDispatch } from "react-redux";
import useTaskDetailsList from "../../hooks/useTaskDetailsList";
import { getCurrentTaskDetails } from "../../slice/taskSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSelectedCardData } from "../../slice/QASlice";
import store from "../../utility/store";
import ExecutorTable from "./ExecutorAdminTable";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
const theme = createTheme({
  components: {
    MuiPickersToolbar: {
      styleOverrides: {
        toolbarBtn: {
          color: "#006FCF",
        },
      },
    },
  },
});
const graphData = [
  { color: "#012544", label: "Visa" },
  { color: "#D84CCA", label: "Discover" },
  { color: "#218B18", label: "Master Card" },
  { color: "#EA001B", label: "Wallet" },
  { color: "#F79F1A", label: "Paypal" },
  { color: "#006FCF", label: "Discover" },
];
const HomePage = () => {
  const [taskStatus, setTaskStatus] = useState("ALL");
  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const progressList = [
    {
      id: 1,
      name: "ALL",
    },
    // {
    //   id: 2,
    //   name: "NEW",
    // },
    {
      id: 2,
      name: "IN-PROCESS",
    },
    {
      id: 3,
      name: "COMPLETED",
    },
    {
      id: 4,
      name: "RELEASED",
    },
  ];

  //Api Calls
  useTaskDetailsList(taskStatus);
  useCountryList();

  //Redux
  const userData = useSelector((state) => state.auth.userData);
  const taskDetailsList = useSelector((state) => state.task.taskDetailsList);
  // const role_id = useSelector((state) => state.auth.userData.role_id);
  // const userData?.role_code = useSelector((state) => state?.auth?.userData?.role_code);

  const handleChangeStatus = (e) => {
    setTaskStatus(e.target.value);
  };

  const handleDownload = (rowData) => {
    console.log("Downloading data:", rowData);
  };

  const handleViewDetails = (rowData) => {
    localStorage.setItem("task_id", rowData?.task_id);
    dispatch(getCurrentTaskDetails(rowData));
    // Navigate to /taskdetails page with the ID or relevant information from rowData
    // navigate("/taskdetails"); // You can include parameters like /taskdetails/:id if needed
   
      navigate("/taskdetails", { state: { rowData } });
  };
  const [onlyReleased, setOnlyReleased] = useState(false);

  useEffect(() => {
    const authorizedEmails = [
      "amxuser@pops.echnotek.com",
      "harishma.k@pops.echnotek.com",
      "pranjali.k@pops.echnotek.com",
      "satyajit@pops.echnotek.com",
      "saket@pops.echnotek.com",
      "dwight@pops.echnotek.com",
      "claire.kennedy@aexp.com",
      "tanya.smith@aexp.com"
    ];
  
    setOnlyReleased(authorizedEmails.includes(userData?.email));
  }, [userData]);
  // const handleViewDetails = async (rowData) => {
  //   localStorage.setItem("task_id", rowData?.task_id);

  //   try {
  //     let taskDetailsLoaded = false;

  //     // Retry mechanism for data loading
  //     for (let attempt = 0; attempt < 3; attempt++) {
  //       await dispatch(getCurrentTaskDetails(rowData));
  //       const taskDetails = store.getState().task.currentTaskDetails;
  //       if (taskDetails) {
  //         taskDetailsLoaded = true;
  //         break;
  //       }
  //       await new Promise((resolve) => setTimeout(resolve, 1000));
  //     }

  //     if (taskDetailsLoaded) {
  //       navigate("/taskdetails", { state: { rowData } });
  //     } else {
  //       // Handle the case where taskDetails is not loaded even after retries
  //       alert(
  //         "Task details are not available at the moment. Please try again later."
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching task details:", error);
  //   }
  // };

  const handleButtonClick = () => {
    setOpenPopup(true);
  };

  useEffect(() => {
    dispatch(getSelectedCardData(null));
  }, []);

  const handleExecutorDashboard=()=>{
    if(userData?.role_code==="executor"){
      navigate("/executor-dashboard");
    }else if(userData?.role_code==="admin"){
      navigate("/executor-tab")
    }
  }
  return (
    <div>
      {/* //////////////////////////////   TOP HEADER   /////////////////////////////////////////        */}

      <Container
        sx={{
          marginTop: "24px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          paddingBottom: "32px",
          flexDirection: "row",
          gap: "1.5rem",
        }}
        maxWidth="2xl"
      >
        {/* <ToastContainer/> */}
        {/* <ExecutorTable/> */}

        {/* <Box>
          <Typography
            variant="h5"
            sx={{ fontSize: "32px", fontWeight: "700", lineHeight: "48px" }}
          >
            Good Morning, {userData?.full_name}
          </Typography>
          <Typography
            sx={{
              color: "#9D9D9D",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
            }}
          >
            Welcome to your Task management system
          </Typography>
        </Box> */}
        <Button
          sx={{ textTransform: "none", borderRadius: "0", visibility: (userData?.role_code==="executor" || userData?.email==="admin@pops.echnotek.com" ) ? "visible" : "hidden" }}
          disableElevation
          variant="contained"
          onClick={handleExecutorDashboard}
        >
          <Stack sx={{display:"flex",flexDirection:"row",alignItems:"center",gap:'0.2rem'}}>
            Executor Dashboard
            <ArrowForwardOutlinedIcon sx={{ fontSize: "17px" }} />
          </Stack>
        </Button>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            alignSelf: {
              xs: "center",
              // sm: "stretch",
              sm: "flex-end",
            },
          }}
        >
          <Box sx={{ display: "flex", alignSelf: "center", gap: "10px" }}>
            {" "}
            <Typography sx={{ alignItems: "center", alignSelf: "center" }}>
              {" "}
              From:{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ maxWidth: "150px" }}
                slotProps={{ textField: { size: "small" } }}
                defaultValue={dayjs("2023-04-17")}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", alignSelf: "center", gap: "10px" }}>
            {" "}
            <Typography sx={{ alignItems: "center", alignSelf: "center" }}>
              {" "}
              To:{" "}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={theme}>
                <DatePicker
                  sx={{ maxWidth: "150px" }}
                  slotProps={{ textField: { size: "small" } }}
                  defaultValue={dayjs("2023-04-17")}
                />
              </ThemeProvider>
            </LocalizationProvider>
          </Box>
          <div>
            {userData?.role_id !== 3 && (
              <Button
                sx={{
                  textWrap: "nowrap",
                  textTransform: "none",
                  borderRadius: "0",
                }}
                variant="contained"
                startIcon={<AddIcon />}
                disableElevation
                onClick={handleButtonClick}
              >
                Create New Task
              </Button>
            )}
            {openPopup && <TaskPopUp setOpenPopup={setOpenPopup} />}
          </div>
        </Box>
      </Container>

      {/* ///////////// TASKWISE GRAPH //////////////////////////////////// */}
      <Stack sx={{ backgroundColor: "#F8F8F8" }}>
        {/* <Stack
          sx={{
            display: "flex",
            backgroundColor: "#fff",
            marginTop: "20px",
            marginLeft: { xs: "16px", md: "32px" },
            marginRight: { xs: "16px", md: "32px" },
            padding: "12px",
          }}
        >
          <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
            <h1
              style={{
                marginLeft: "25px",
                marginBottom: "50px",
                fontSize: "24px",
                fontWeight: "400",
                lineHeight: "36px",
                width: "fit-content",
              }}
            >
              Task Analysis
            </h1>
            <Paper
              elevation={0}
              sx={{
                padding: 2,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "80%",
                justifyContent: "space-around",
              }}
            >
              <Typography variant="h6" gutterBottom>
        Legend
      </Typography>
              {graphData.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: 1,
                  }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginRight: 1,
                      color: item.color,
                    }}
                  />
                  <Typography variant="p">{item.label}</Typography>
                </div>
              ))}
            </Paper>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: {
                xs: "center",
                md: "space-between",
              },
            }}
          >
            <ChartAnalysis />
            <ChartAnalysis />
            <ChartAnalysis />
            <ChartAnalysis />
            <ChartAnalysis />
            <ChartAnalysis />
            <ChartAnalysis />
            <ChartAnalysis />
          </Stack>
        </Stack> */}

        {/* /////////////////////////////////// POP RESULT TAB //////////////////////////////////////// */}
        {/* <GraphTable /> */}

        {/* //////////////////////////////////////   LIST OF TASK TABLE ////////////////////////////////////// */}
        <Stack
          sx={{
            backgroundColor: "#F8F8F8",
            marginTop: "30px",
            marginLeft: { xs: "16px", md: "32px" },
            marginRight: { xs: "16px", md: "32px" },
            paddingBottom: "32px",
          }}
        >
          <Stack
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
                marginTop: "-2rem",
              },
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#F8F8F8",
            }}
          >
            <h3
              style={{
                marginLeft: "10px",
                marginBottom: "10px",
                fontSize: "24px",
                fontWeight: "400",
                lineHeight: "36px",
              }}
            >
              List of Tasks
            </h3>
            <div style={{ display: "flex", alignItems: "center" }}>
              Status:
              <FormControl
                sx={{ m: 1, width: 150, backgroundColor: "#fff" }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label">All</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={taskStatus}
                  label="Task"
                  onChange={handleChangeStatus}
                  sx={{
                    ".MuiSvgIcon-root ": {
                      fill: "#006FCF !important",
                    },
                    borderRadius: "0",
                  }}
                >
                  {progressList?.map((progress) => (
                    <MenuItem value={progress?.name}>{progress?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Stack>
          <TableContainer
            sx={{ maxHeight: "600px" }}
            elevation={0}
            component={Paper}
          >
            <Table sx={{ border: "1px solid #E8E8E8" }}>
              <TableHead
                sx={{
                  color: "#9D9D9D",
                  fontSize: "14px",
                  fontWeight: "400",
                  textAlign: "center",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#FFF", // Set background color as needed
                  zIndex: 10,
                }}
              >
                {/* First row for common headings */}
                {/* <TableRow style={{ height: "20px" }}>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "400",
                      border: "none",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "400",
                      border: "none",
                    }}
                  ></TableCell>
                  <TableCell
                    colSpan={4}
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      border: "none",
                      fontWeight: "400",
                      textAlign: "center",
                      borderRadius: "0px 0px 20px 20px",
                      backgroundColor: "rgba(0, 111, 207, 0.06)",
                    }}
                  >
                    Dates
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "400",
                      border: "none",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "400",
                      border: "none",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "400",
                      border: "none",
                    }}
                  ></TableCell>
                </TableRow> */}

                {/* Second row for individual column headings */}
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Task
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Total URL’s
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Added
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Start
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Completed
                  </TableCell> */}
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Release
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  >
                    Cheat sheet
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#9D9D9D",
                      fontSize: "14px",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taskDetailsList?.map((rowData, index) => (
                  <TableRow
                    sx={{ fontSize: "16px", fontWeight: "400" }}
                    key={index}
                  >
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {rowData.task_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {rowData.total_urls}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {rowData.added_date}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {rowData.started_date}
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {rowData.completed_date}
                    </TableCell> */}
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {rowData.released_date}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          borderRadius: "32px",
                          fontSize: "12px",
                          fontWeight: "700",
                          margin: "8px",
                          padding: "8px 10px",
                          color: (() => {
                            switch (rowData.task_status.toLowerCase()) {
                              case "Not Started".toLowerCase():
                                return "#FF5151";
                              case "Released".toLowerCase():
                                return "#0AB32F";
                              case "In Progress".toLowerCase():
                                return "#FF9131";
                              case "Completed".toLowerCase():
                                return "#52B9E5";
                              default:
                                return "inherit";
                            }
                          })(),
                          backgroundColor: (() => {
                            switch (rowData.task_status.toLowerCase()) {
                              case "Not Started".toLowerCase():
                                return "#FFE8E8";
                              case "Released".toLowerCase():
                                return "#DBF6E1";
                              case "In Progress".toLowerCase():
                                return "#FFEFE1";
                              case "Completed".toLowerCase():
                                return "#E8F8FF";
                              default:
                                return "inherit";
                            }
                          })(),
                        }}
                      >
                        {rowData.task_status}
                      </p>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Button
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          textTransform: "none",
                        }}
                        variant="text"
                        startIcon={<FileDownloadOutlinedIcon />}
                        onClick={() => handleDownload(rowData)}
                      >
                        Download
                      </Button>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", textWrap: "nowrap" }}>
                      <Button
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "none",
                          borderRadius: "0",
                          padding: "6px 24px",
                        }}
                        disabled={
                          onlyReleased && rowData?.task_status !== "RELEASED"
                            ? true
                            : userData?.role_code === "admin" ||
                              userData?.role_code === "executor"
                            ? false
                            : userData?.role_code === "customer" &&
                              rowData?.task_status === "RELEASED"
                            ? false
                            : true
                        }
                        variant="outlined"
                        onClick={() => handleViewDetails(rowData)}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </div>
  );
};

export default HomePage;
